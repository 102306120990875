import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Practices from "../../api/Practices";
import Sidebar from "./Sidebar";
import FaqSection from "./FaqSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllServices,
  getSpecialService,
} from "../../store/pages/servicesSlice";

const PracticeSinglePage = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);

  const { service, loading } = useSelector((state) => state.services);
  console.log(service?.jop_description);

  useEffect(() => {
    dispatch(getSpecialService(location?.state?.practice_id));
    window.scrollTo(10, 0);
  }, [dispatch]);

  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
      <PageTitle pageTitle={service?.title} pagesub={"الخدمة"} />
      <section
        className={`wpo-service-single-section section-padding wpo-practice-section-s2 `}
      >
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-2 col-md-12 order-lg-3 order-1 mt--60">
              <div className="rule-area">
                <div className="wpo-service-single-sub-img ">
                  <img src={service?.image} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 order-lg-2 order-1">
              <div className="wpo-service-single-wrap">
                <div className="wpo-service-single-content">
                  {/* <img src={PracticeDetails?.image} alt="" /> */}
                  <div className="wpo-service-single-content-des ">
                    {/*<h2>{service?.title}</h2>*/}
                    <p
                      className="code-content"
                      dangerouslySetInnerHTML={{
                        __html: service?.jop_description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default PracticeSinglePage;
