import React, { Fragment, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useLocation, useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getAllArticals, getSpecialService } from "../../store/pages/articalsSlice";
import CommentForm from "./CommentForm";

const ArticalSinglePage = (props) => {
    const { title } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const { artical, loading } = useSelector((state) => state.articals);
    console.log(artical)
    useEffect(() => {
        dispatch(getSpecialService(location?.state?.artical_id));
        window.scrollTo(10, 0);
    }, [dispatch]);
    console.log(artical?.content);

    return (
        <Fragment>
            <Navbar hclass={"wpo-header-style-5"} topbarClass={"tb-block"} />
            <PageTitle pageTitle={artical?.content} pagesub={"مقالات"} />
            <section
                className={`wpo-service-single-section section-padding wpo-practice-section-s2 `}
            >
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-lg-2 col-md-12 order-lg-3 order-1 mt--60">
                            <div className="rule-area">
                                <div className="wpo-service-single-sub-img ">
                                    <img src={artical?.image} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-content">
                                    <div className="wpo-service-single-content-des ">
                                        <h2>{artical?.content}</h2>
                                        <p
                                            className="code-content"
                                            dangerouslySetInnerHTML={{
                                                __html: artical?.description,
                                            }}
                                        ></p>
                                        <div className="wpo-contact-pg-section section-padding">

                                            <div className="wpo-practice-section-s2">
                                                <div className="wpo-section-title">
                                                    <h2> اترك تعليقاً</h2>
                                                    <p>
                                                        لن يتم نشر عنوان بريدك الإلكتروني. الحقول الإلزامية مشار
                                                        إليها بـ *
                                                    </p>
                                                </div>
                                                <CommentForm />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <Sidebar />

                    </div>
                </div>
            </section>

            <Footer />
            <Scrollbar />
        </Fragment>
    );
};
export default ArticalSinglePage;
