import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getSpecialEmployee } from "../../store/pages/employeesSlice";
import { getAllPartners } from "../../store/pages/partnersSlice";

const OurPartners = (props) => {
  const { partners } = useSelector((state) => state.partners);
  // const partners = []
  const dispatch = useDispatch();
  const ClickHandler = (id) => {
    window.scrollTo(10, 0);
    dispatch(getSpecialEmployee(id));
  };
  const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: partners?.length ? partners?.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    rtl: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getAllPartners());
  }, [dispatch]);

  return (
    <section className="wpo-team-section section-padding pt-0">
      <div className="container">
        <div className="wpo-team-wrap">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="wpo-section-title">
                {/* <span>العمل مع الأفضل</span> */}
                <h2 style={{ color: "rgb(175, 145, 78)" }}>شركاء المؤسسة بالخارج</h2>
              </div>
            </div>
          </div>
          <div className="wpo-team-active mt-sm-0 mt-2">
            <Slider {...settings}>
              {partners?.map((attorney, aitem) => (
                <div className="wpo-team-item" key={aitem}>
                  <div className="wpo-team-img">
                    <img src={attorney.logo} alt="" />
                  </div>
                  <div className="wpo-team-text">
                    <h2>
                      {/* <Link
                        onClick={() => ClickHandler(attorney.id)}
                        to={`/attorneys-single/${attorney.name}`}
                      > */}
                      {attorney.name}
                      {/* </Link> */}
                    </h2>
                    <span>{attorney.title}</span>
                    <div className="social">
                      {/* <ul>
                        <li>{attorney.description}</li>
                         </ul> */}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
