import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/pages/profileSlice";

function FileProfile() {
  const { profile, loading } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!profile.file) {
      dispatch(getProfile());
    }
  }, [dispatch, profile.file]);

  return (
    <div
      className="  overflow-hidden text-center frame body-bg"
      style={{ backgroundColor: "#001635" }}
      role="document"
    >
      {loading || !profile.file ? (
        <div className="spinner-border text-primary my-5" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <iframe
          title="PDF Viewer"
          role="document"
          width="100%"
          height="100%"
          name="profile"
          id="profile"
          src={`${profile.file}#navpanes=none&toolbar=0&zoom=page-fit`}
          className="border-0 vh-100 overflow-Y-scroll-none "
        />
      )}
    </div>
  );
}

export default FileProfile;
