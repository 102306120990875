import React from "react";
import { Link } from "react-router-dom";
import himg from "../../images/slider/salah.png";
import zIndex from "@mui/material/styles/zIndex";

const Hero = () => {
  return (
    <>
      <section className="static-hero">
        <div className="hero-container">
          <div
            className="hero-inner"
            style={{ display: "block", margin: "3rem 0px " }}
          >
            <div className="container row ">
              <div
                className="hero-text-wrap col-12 col-md-6"
                style={{ marginTop: "3.4rem" }}
              >
                <div
                  className="sub-title text-justify"
                  style={{ maxWidth: "45rem" }}
                >
                  <span
                    style={{
                      fontSize: "2rem",
                      lineHeight: "2.5rem",
                    }}
                  >
                    مؤسسة الجمل للمحاماة والاستشارات القانونية
                  </span>
                </div>
                <div className="slide-title ">
                  <h2
                    style={{
                      fontSize: "1.6rem",
                      lineHeight: "2.5rem",
                      textAlign: "justify",
                      margin: "6rem 0px 0px 0px",
                    }}
                  >
                    لدى مؤسسة الجمل للمحاماة فريق عمل من المحترفين المتخصصين في
                    كافة مجالات أعمال المحاماة والاستشارات القانونية على أتم
                    استعداد لدعمك القانوني وحماية مصالحكم
                  </h2>
                </div>
                <div className="slide-text text-justify">
                  <p
                    style={{
                      textAlign: "justify",
                      lineHeight: "2.5rem",
                    }}
                  >
                    تأسست المؤسسة عام 2015 و لما كان الالتزام بالسرية التامة أهم
                    وأول مبادئ مؤسسة الجمل للمحاماة، فإننا نلتزم بعدم الإفصاح
                    والتفاخر بإنجازات المؤسسة في أروقة المحاكم وفي قطاع
                    الاستثمارات الدولية وغيرها من قطاعات الأعمال المتنوعة التي
                    تتولاها المؤسسة، لأننا نؤمن بأن نجاحنا فيما نحققه لصالح
                    عملائنا من إنجازات يُعد أمرًا شديد الخصوصية ويندرج خلف مبدأ
                    الالتزام بالسرية التامة لصالح عملائنا.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div data-swiper-parallax="500" className="slide-btns">
                  <Link to="/about" className="theme-btn-s2">
                    اكتشف المزيد
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 position-relative  ">
                <div className=" top-0 ">
                  <h1
                    className="sub-title text-center "
                    style={{ margin: "0px 0px 3rem 0px" }}
                  >
                    <span className="text-center block ">
                      خلية خبراء القانون
                    </span>
                  </h1>
                  <div
                    className="lawyer-shape"
                    style={{ border: "1rem solid #af914e" }}
                  >
                    <img
                      className="position-relative w-100 h-100 "
                      src={himg}
                      alt=""
                    />
                  </div>
                  {/* <div
                    className="exprience-left    d-flex align-items-center flex-column position-absolute bottom-0 "
                    style={{
                      transform: "translateX(57%)",
                    }}
                  >
                    <h6
                      className="fw-bold hero-img-text"
                      style={{
                        fontSize: "8rem",
                        color: "#af914e",
                      }}
                    >
                      9
                    </h6>
                    <h6
                      className=" fs-2 fw-bold hero-img-text"
                      style={{
                        color: "#af914e",
                      }}
                    >
                      سنوات خبرة
                    </h6>
                    <div
                      className="position-absolute h-100 w-25 bg-light "
                      style={{ zIndex: "-1" }}
                      translate="translateY(1rem)"
                    ></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
