import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
import HomePage2 from "../HomePage2/HomePage2";
import HomePage3 from "../HomePage3/HomePage3";
import HomePage4 from "../HomePage4/HomePage4";
import AboutPage from "../AboutPage/AboutPage";
import PricingPage from "../PricingPage/PricingPage";
import AttorneysPage from "../AttorneysPage/AttorneysPage";
import AttorneySinglePage from "../AttorneySinglePage/AttorneySinglePage";
import PracticeSinglePage from "../PracticeSinglePage/PracticeSinglePage";
import CaseSinglePage from "../CaseSinglePage/CaseSinglePage";
import ShopPage from "../ShopPage";
import ProductSinglePage from "../ProductSinglePage";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
import OrderRecived from "../OrderRecived";
import BlogPage from "../BlogPage/BlogPage";
import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
import BlogDetails from "../BlogDetails/BlogDetails";
import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
import TermPage from "../TermPage/TermPage";
import PrivacyPage from "../PrivacyPage/PrivacyPage";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import FaqPage from "../FaqPage";
import PracticePage from "../PracticePage/PracticePage";
import PracticePageS2 from "../PracticePageS2/PracticePageS2";
import PracticePageS3 from "../PracticePageS3/PracticePageS3";
import CasePage from "../CasePage/CasePage";
import CasePageS2 from "../CasePageS2/CasePageS2";
import CasePageS3 from "../CasePageS3/CasePageS3";
import OurTeam from "../../main-component/OurTeam/ourTeam";
import ServicesTechnology from "../ServicesTechnology/ServicesTechnology";
import Profile from "../FileProfile/fileProfile";
import { RecommendWebsite } from "../RecommendWebsite/RecommendWebsite";
import Articals from "../Articals/ViewArticals";
import AuthComponent from "../../components/Autheticat/AuthComponent";
import ArticalSinglePage from "../Articals/singleartical";
import EmployeeProfile from "../../components/attorneys2/employeeProfile";
import Materials from "../materials/ViewMatrial";
import MaterialSinglePage from "../materials/singleMaterial";
import ContactCompanies from "../ContactPage/Companies";
import ContactApplicant from "../ContactPage/applicant";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="home" element={<Homepage />} />
          {/*<Route path="home2" element={<HomePage2 />} />*/}
          {/*<Route path="home3" element={<HomePage3 />} />*/}
          {/*<Route path="home4" element={<HomePage4 />} />*/}
          <Route path="about" element={<AboutPage />} />
          <Route path="attorneys" element={<AttorneysPage />} />
          <Route path="case-single/:slug" element={<CaseSinglePage />} />
          <Route
            path="attorneys-single/:slug"
            element={<AttorneySinglePage />}
          />
          <Route path="artical-single/:title" element={<ArticalSinglePage />} />
          <Route path="material-single/:id" element={<MaterialSinglePage />} />
          <Route
            path="practice-single/:title"
            element={<PracticeSinglePage />}
          />
          <Route path="articals" element={<Articals />} />
          <Route path="materials" element={<Materials />} />
          <Route path="terms" element={<TermPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="faq" element={<FaqPage />} />
          <Route path="practice" element={<PracticePage />} />
          <Route path="practice-s2" element={<PracticePageS2 />} />
          <Route path="practice-s3" element={<PracticePageS3 />} />
          <Route path="case" element={<CasePage />} />
          <Route path="case-s2" element={<CasePageS2 />} />
          <Route path="case-s3" element={<CasePageS3 />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="product-single/:slug" element={<ProductSinglePage />} />
          <Route path="cart" element={<CartPage />} />
          <Route element={<AuthComponent />}>
            <Route path="checkout" element={<CheckoutPage />} />
            <Route path="order_received" element={<OrderRecived />} />
          </Route>
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route
            path="blog-single-left-sidebar/:slug"
            element={<BlogDetailsLeftSiide />}
          />
          <Route
            path="blog-single-fullwidth/:slug"
            element={<BlogDetailsFull />}
          />
          <Route path="contact" element={<ContactPage />} />
          <Route path="contact-company" element={<ContactCompanies />} />
          <Route path="contact-applicant" element={<ContactApplicant />} />
          <Route path="profile/:id" element={<EmployeeProfile />} />
          <Route path="/*" element={<ErrorPage />} />
          <Route path="signin" element={<LoginPage />} />
          <Route path="register" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="ourteam" element={<OurTeam />} />
          {/*<Route path='services' element={<ServicesTechnology/>}/>*/}
          <Route path="profile" element={<Profile />} />
          <Route path="recommendwebsite" element={<RecommendWebsite />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
